import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import { connect } from 'redux-zero/react'
import actions from '../store/actions'
import { get, isNil } from 'lodash'
import { Box } from '@material-ui/core'

import styled from 'styled-components'
import 'bootstrap/dist/css/bootstrap.css'
import Keys from '../utils/Keys'
import PostContainer from '../components/Elements/NewsFeed/PostContainer'
import PostModal from '../components/Elements/NewsFeed/PostModal'
import AddPostBtn from '../components/Elements/NewsFeed/AddPostBtn'
import FetchBtn from '../components/Elements/NewsFeed/FetchPostsBtn'
import FullScreenLoader from '../components/FullScreenLoader'
import ValidationPassword from '../components/Elements/ValidationPassword'
import withGroups from '../components/Elements/withGroups'
import Select from '../components/Elements/SingleGroupSelector'
const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  margin: 10px;
`

const PostsPage = props => {
  const [posts, setPosts] = useState(props.posts)

  useEffect(() => {
    setPosts(props.posts)
  }, [props.posts])

  const { selectedGroup, groups } = props
  return (
    <Layout>
      <HeaderStyled>
        <h1>Posts</h1>
      </HeaderStyled>
      <ValidationPassword />
      <br />
      <br />
      <Box display="flex" flexDirection={'row'}>
        <Select
          label="Groups"
          className="tasksFetcherSelect"
          value={
            get(selectedGroup, Keys.OBJECT_ID, false)
              ? selectedGroup[Keys.OBJECT_ID]
              : null
          }
        />
        <AddPostBtn {...props} />

        <FetchBtn {...props} posts={posts} overRidePosts={true} />
      </Box>
      <PostContainer posts={posts} {...props} />
      <PostModal />
      <FullScreenLoader loading={props.loading} />
    </Layout>
  )
}

export default withGroups(PostsPage)

import React from 'react'
import '../styles/formStyle.css'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import {
  convertUnderscoreNameToLabel,
  generateID,
  generateTagList,
  getRsid,
  isSolid,
  tagsToList,
} from '../../../utils/taskHandler'
import Input from '../FormElements/Input'
import Keys from '../../../utils/Keys'
import isNil from 'lodash/isNil'
import { InputLabel, Box, CardMedia } from '@material-ui/core'
import Button from '../Buttons/Button'
import { getCommentsCount, postRequest } from '../../../services/api'
import UploadFile from '../UploadFile'
import FileService from '../../../services/fileServices'
import SortableEditableListWrapper from '../Lists/SortableEditableListWrapper'
import styled from 'styled-components'
import Select from '../FormElements/Select'
import {
  epochTime2date,
  getNow,
  timestamp2tableDate,
} from '../../../utils/dateHandler'
import CommentSection from '../Comments/CommentSection'
const Img = styled.img`
  border-radius: 5px;
  margin: 0 2px 0 2px;
`
const NO_REF = -1

class PostForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...this._setState() }
  }

  _setState = () => {
    return {
      images:
        this.props.editPost && !isNil(this.props.selectedPost[Keys.IMAGES])
          ? generateTagList(this.props.selectedPost[Keys.IMAGES])
          : [],
      title:
        this.props.editPost && !isNil(this.props.selectedPost[Keys.TITLE])
          ? this.props.selectedPost[Keys.TITLE]
          : '',
      ref_id:
        this.props.editPost &&
        !isNil(this.props.selectedPost[Keys.REFERENCE_ID])
          ? this.props.selectedPost[Keys.REFERENCE_ID]
          : NO_REF,
      post_id:
        this.props.editPost && !isNil(this.props.selectedPost['post_id'])
          ? this.props.selectedPost['post_id']
          : generateID(),
      videos:
        this.props.editPost &&
        !isNil(this.props.selectedPost[Keys.VIDEOS]) &&
        this.props.selectedPost[Keys.VIDEOS].length
          ? this.props.selectedPost[Keys.VIDEOS][0]
          : '',
      created_at_ts:
        this.props.editPost &&
        !isNil(this.props.selectedPost[Keys.CREATED_AT_TS])
          ? this.props.selectedPost[Keys.CREATED_AT_TS]
          : getNow(),
      index: this.props.editPost ? this.props.selectedPost['index'] : -1,
      haveComments: false,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.editPost !== prevProps.editPost ||
      this.props.selectedPost !== prevProps.selectedPost ||
      this.props.selectedPost['post_id'] !== prevProps.selectedPost['post_id']
    ) {
      this.setState({
        ...this._setState(),
      })
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleListChange = (value, name) => {
    this.setState({
      [name]: value,
    })
  }

  handleResponse = (response, method = 'POST') => {
    this.props.hideFullScreenLoader()
    if (response.status === 200) {
      let { posts } = this.props
      const { index } = this.state
      if (method === 'DELETE') {
        posts.splice(index, 1)
        this.props.setPosts([...posts])
        this.closeModal()
      }

      this.props.handleAlert({
        text: 'your operations on Post was saved',
        type: 'success',
      })
    } else {
      this.props.handleAlert({
        text: 'some error acured, so changes might not be saved',
        type: 'error',
      })
    }
  }

  closeModal = () => {
    this.props.setEditPost(false)
    this.props.setShowPostModal(false)
  }

  printLinkOptions = () => {
    const { postRef, taskTypesView } = this.props
    let options = [
      <option id={NO_REF} key={NO_REF} value={NO_REF}>
        No links
      </option>,
    ]

    Object.keys(taskTypesView).forEach(taskSubTypeLabel => {
      options.push(
        <option
          id={taskTypesView[taskSubTypeLabel]}
          key={taskTypesView[taskSubTypeLabel]}
          value={`tasks-${taskTypesView[taskSubTypeLabel]}`}
        >
          {`Tasks-${taskSubTypeLabel}`}
        </option>
      )
    })
    if (postRef.length < 1) {
      return options
    }
    postRef.forEach(item => {
      options.push(
        <option id={item.ref_id} key={item.ref_id} value={item.ref_id}>
          {item.title}
        </option>
      )
    })

    return options
  }

  handleImageUpload = async (e, imageName) => {
    this.props.showFullScreenLoader()
    const file = e.target.files[0]
    let files = this.state[imageName]

    const res = await FileService.uploadFile(file, this.props.selected_rid)
    files.push({ tag: res })
    this.setState({ [imageName]: [...files] })
    this.props.hideFullScreenLoader()
  }

  handleSubmit = event => {
    event.preventDefault()
    const {
      ref_id,
      title,
      images,
      post_id,
      index,
      videos,
      created_at_ts,
    } = this.state
    const { editPost, selected_rid, posts, selectedGroup } = this.props
    let method = 'POST'
    let new_post = {
      title: title,
      post_id: post_id,
      rid: selected_rid,
      type: Keys.POST_GENERAL_TYPE,
    }
    if (ref_id !== NO_REF) {
      new_post[Keys.TYPE] = Keys.POST_REF_TYPE
      new_post[Keys.REFERENCE_ID] = ref_id
    }
    if (images !== null) {
      new_post[Keys.IMAGES] = tagsToList(images)
    }

    if (videos !== null && videos.length > 0) {
      new_post[Keys.VIDEOS] = [videos]
    }
    if (editPost) {
      method = 'PUT'
      new_post[Keys.RAS_ID] = getRsid(
        selected_rid,
        selectedGroup[Keys.OBJECT_ID]
      )
    } else {
      new_post[Keys.GROUP] = selectedGroup[Keys.OBJECT_ID]
    }
    postRequest(new_post, this.handleResponse, method)
    new_post[Keys.CREATED_AT_TS] = created_at_ts

    if (editPost) {
      posts[index] = new_post
    } else {
      posts.unshift(new_post)
    }

    this.props.setPosts([...posts])
    this.closeModal()
  }

  handleDelete = event => {
    event.preventDefault()
    this.props.showFullScreenLoader()
    const { selected_rid, selectedPost } = this.props
    const { post_id } = this.state
    const ras_id = selectedPost[Keys.RAS_ID]
    postRequest(
      { ras_id: ras_id, rid: selected_rid, post_id: post_id },
      this.handleResponse,
      'DELETE'
    )
  }

  preventSubmit = e => {
    const key = e.charCode || e.keyCode || 0
    if (key === 13) {
      e.preventDefault()
    }
  }

  render() {
    const { post_id, title, ref_id, created_at_ts, images, videos } = this.state
    const { editPost } = this.props

    return (
      <form onSubmit={this.handleSubmit} onKeyPress={this.preventSubmit}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Input
            value={post_id}
            onChange={this.handleChange}
            type="text"
            name={'post_id'}
            readOnly={true}
            label={'Post ID'}
          />

          <Input
            value={title}
            onChange={this.handleChange}
            type="text"
            name={Keys.TITLE}
            required={true}
            label={'Post title'}
          />
        </Box>

        <Box display={'flex'} justifyContent={'space-between'}>
          <Select
            label="Link"
            name={Keys.REFERENCE_ID}
            value={ref_id}
            onChange={this.handleChange}
            required={true}
          >
            {this.printLinkOptions()}
          </Select>

          {editPost ? (
            <Input
              value={timestamp2tableDate(epochTime2date(created_at_ts))}
              type={'text'}
              readOnly={true}
              label={convertUnderscoreNameToLabel(Keys.CREATED_AT_TS)}
            />
          ) : null}
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'baseline'}>
          <Input
            value={videos}
            onChange={this.handleChange}
            type="text"
            name={'videos'}
            label={'Video'}
          />
          {isSolid(videos) ? (
            <CardMedia
              component="video"
              image={videos}
              title="title"
              controls
              height="140"
            />
          ) : null}

          <SortableEditableListWrapper
            items={images}
            setItems={this.handleListChange}
            label={'Images'}
            name={Keys.IMAGES}
          >
            <UploadFile
              onChange={e => this.handleImageUpload(e, Keys.IMAGES)}
            />
          </SortableEditableListWrapper>

          <div>
            {images.map((image, index) => (
              <Img key={index} width={100} src={image.tag} />
            ))}
          </div>
        </Box>
        {editPost ? (
          <React.Fragment>
            {' '}
            <InputLabel shrink id="label">
              Comments
            </InputLabel>
            <CommentSection oid={post_id} />
          </React.Fragment>
        ) : null}

        <li className="formButtonDash">
          <Button
            type={'submit'}
            label={editPost ? 'Save Item Changes' : 'Add Item'}
            color={'primary'}
          />
          {editPost ? (
            <Button
              onClick={this.handleDelete}
              label={'Delete Post'}
              color={'secondary'}
            />
          ) : null}
        </li>
      </form>
    )
  }
}

const mapStateToProps = ({
  editPost,
  selectedPost,
  selectedGroup,
  postRef,
  selected_rid,
  posts,
  taskTypesView,
}) => ({
  editPost,
  selectedPost,
  selectedGroup,
  postRef,
  selected_rid,
  posts,
  taskTypesView,
})
export default connect(
  mapStateToProps,
  actions
)(PostForm)

import React from 'react'
import { connect } from 'redux-zero/react/index'
import actions from '../../../store/actions'
import Keys from '../../../utils/Keys'
import { get, isNil } from 'lodash'
import { getUserReports, getUserTasks } from '../../../services/api'
import { handleResponseError, isSolid } from '../../../utils/taskHandler'
import AlertMassages from '../../../utils/AlertMassages'
import { getNow } from '../../../utils/dateHandler'

const withRefIdUpdate = WrappedComponent => {
  const WithRefIdUpdate = props => {
    const handlerReportResponse = (postRef, response) => {
      if (response !== undefined && response.status === 200) {
        let storeReports = response.reports
        if (
          isNil(storeReports) ||
          storeReports.every(element => element === null)
        ) {
          props.handleAlert({
            text: 'Did not got any Reports to reference',
            type: 'info',
          })
        }

        storeReports.forEach((reports, index) => {
          if (isSolid(reports) && isSolid(reports[Keys.REFERENCE_ID])) {
            const title = `Report: ${reports['date']}`

            postRef.push({
              title: title,
              ref_id: reports[Keys.REFERENCE_ID],
            })
          }
        })

        const uniqueArrayPostRef = postRef.filter((item, index) => {
          const _item = JSON.stringify(item)
          return (
            index ===
            postRef.findIndex(obj => {
              return JSON.stringify(obj) === _item
            })
          )
        })

        props.setPostRef([...uniqueArrayPostRef])
      } else {
        props.handleAlert({
          text: AlertMassages[response.status.toString()],
          type: 'error',
        })
      }
    }

    const handleTaskResponse = response => {
      let title = ''
      if (response !== undefined && response.status === 200) {
        let storeTasks = response.store_tasks
        if (isNil(storeTasks)) {
          props.handleAlert({
            text: 'Did not got any tasks to reference',
            type: 'info',
          })
          storeTasks = []
        }

        const { postRef, selected_rid, selectedGroup } = props
        let newPostRef = [...postRef]

        storeTasks.forEach((task, index) => {
          if (isSolid(task[Keys.REFERENCE_ID])) {
            if (task[Keys.TYPE] === Keys.TASK_TYPE_REPORT) {
              title = task[Keys.PAGE_TITLE]
            } else {
              title = task[Keys.TITLE]
            }

            newPostRef.push({ title: title, ref_id: task[Keys.REFERENCE_ID] })
          }
        })

        const paramsReport = {
          rid: selected_rid,
          group: selectedGroup[Keys.OBJECT_ID],
          date: getNow('YYYYMMDD'),
          postRef: newPostRef,
        }
        getUserReports(
          handlerReportResponse,
          paramsReport,
          props.hideFullScreenLoader
        )
      } else {
        props.handleAlert({
          text: AlertMassages[response.status.toString()],
          type: 'error',
        })
      }
    }

    const updateRefIds = () => {
      const { selected_rid, selectedGroup } = props
      if (isSolid(selectedGroup)) {
        props.showFullScreenLoader()
        const params = {
          rid: selected_rid,
          byDates: false,
          groups: selectedGroup[Keys.OBJECT_ID],
        }
        props.showFullScreenLoader()
        getUserTasks(handleTaskResponse, params, props.hideFullScreenLoader)
      }
    }

    return <WrappedComponent updateRefIds={updateRefIds} {...props} />
  }

  const mapStateToProps = ({
    selected_rid,
    userRidsDetails,
    editPost,
    selectedPost,
    selectedGroup,
    postRef,
    taskTypesView,
  }) => ({
    selected_rid,
    userRidsDetails,
    editPost,
    selectedPost,
    selectedGroup,
    postRef,
    taskTypesView,
  })

  return connect(
    mapStateToProps,
    actions
  )(WithRefIdUpdate)
}

export default withRefIdUpdate

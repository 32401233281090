import React from 'react'
import { PropTypes } from 'prop-types'
import '../styles/formStyle.css'
import NativeSelect from '@material-ui/core/NativeSelect'
import { Select as Slt } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const Label = styled(InputLabel)`
  width: max-content;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`
const Select = props => {
  const classes = useStyles()
  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  return (
    <Wrapper>
      <Label shrink ref={inputLabel} id="label">
        {props.label}
      </Label>
      {props.multiple === false ? (
        <NativeSelect
          inputProps={{
            name: props.name,
          }}
          labelwidth={labelWidth}
          value={props.value}
          onChange={props.onChange}
          required={props.required}
          disabled={props.disabled}
        >
          {props.children}
        </NativeSelect>
      ) : (
        <Slt
          inputProps={{
            name: props.name,
          }}
          labelWidth={labelWidth}
          defaultValue={props.value}
          onChange={props.onChange}
          required={props.required}
          disabled={props.disabled}
          multiple
          native
        >
          {props.children}
        </Slt>
      )}
    </Wrapper>
  )
}

Select.defaultProps = {
  required: false,
  multiple: false,
  disabled: false,
}

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
}

export default Select

import React from 'react'
import PropTypes from 'prop-types'
import { Button as Btn } from 'rebass'
import styled from 'styled-components'
import './style.css'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import { isNil, invert } from 'lodash'
import { epochTime2date, timestamp2tableDate } from '../../../utils/dateHandler'
import LinkIcon from '../../../assets/svg-icons/link.svg'
import withRefIdUpdate from './withRefIdUpdate'
import CommentCounter from '../Comments/CommentCounter'
import { isSolid } from '../../../utils/taskHandler'
import { CardMedia, makeStyles } from '@material-ui/core'

const PostWrapper = styled.div`
  margin: 10px;
  background-color: #fff;
  color: black;
  display: flex;
  flex-direction: row;
  padding: 20px;
  padding-left: 20px;
  justify-content: space-between;
  border-radius: 24px;
  width: 50%;
  min-height: 100px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all 300ms ease-in-out;
  &: hover {
    margin-bottom: 20px;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.5);
    transition: all 300ms ease-in-out;
    transform: scale(1.1);
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
`
const Img = styled.img`
  border-radius: 5px;
  margin: 0 2px 0 2px;
`

const useStyles = makeStyles({
  root: {
    maxWidth: 200,
  },
})

const Post = props => {
  const classes = useStyles()

  const onClick = async () => {
    await props.updateRefIds()
    props.setSelectedPost({ ...props.post, index: props.index })
    props.setShowPostModal(true)
    props.setEditPost(true)
  }

  const linkText = () => {
    const { taskTypesView, postRef, post } = props
    let linkTitle = 'no link'
    if (!isNil(post.ref_id)) {
      linkTitle = 'Link is broken!'
      if (post.ref_id.startsWith('tasks-')) {
        const invertTaskSubTypeMap = invert(taskTypesView)
        const [pref, TaskType] = post.ref_id.split('-')
        linkTitle = pref + ' ' + invertTaskSubTypeMap[TaskType]
      }
      postRef.forEach(ref => {
        if (ref.ref_id === post.ref_id) {
          linkTitle = ref.title
        }
      })
    }
    return (
      <span>
        {' '}
        <img
          src={LinkIcon}
          style={{ width: '18px', marginRight: '4px' }}
        />{' '}
        {linkTitle}
      </span>
    )
  }

  return (
    <PostWrapper onClick={onClick}>
      <Col>
        <span style={{ fontSize: '30px' }}>{props.post.title}</span>
        <span>
          Created at: {'  '}
          {timestamp2tableDate(epochTime2date(props.post.created_at_ts))}
        </span>
        {!isNil(props.post.ref_id) ? linkText() : null}
        <span>
          Comments:{'  '}
          {<CommentCounter oid={props.post.post_id} />}
        </span>
        <span>
          Original Group:{'  '}
          {props.getGroupNameFromRasID(props.post.ras_id)}
        </span>
      </Col>
      <div>
        {!isNil(props.post.images)
          ? props.post.images.map((image, index) => (
              <Img
                key={index}
                width={
                  props.post.images.length > 3
                    ? 300 / props.post.images.length
                    : 100
                }
                src={image}
              />
            ))
          : null}
        {isSolid(props.post.videos)
          ? props.post.videos.map((video, index) => (
              <CardMedia
                className={classes.root}
                key={index}
                controls
                component="video"
                image={video}
                title="title"
              />
            ))
          : null}
      </div>
    </PostWrapper>
  )
}

Post.defaultProps = {
  post: {},
}

Post.propTypes = {
  post: PropTypes.object,
}

export default withRefIdUpdate(Post)

import React from 'react'
import IconButtonWrapper from '../Buttons/IconButton'
import Add from '@material-ui/icons/AddRounded'

import withRefIdUpdate from './withRefIdUpdate'
import { isSolid } from '../../../utils/taskHandler'

const AddPostBtn = props => {
  const openNewPostModal = async () => {
    await props.updateRefIds()
    props.setEditPost(false)
    props.setShowPostModal(true)
  }

  return (
    <IconButtonWrapper
      type="button"
      color={isSolid(props.selectedGroup) ? 'green' : 'default'}
      onClick={
        isSolid(props.selectedGroup)
          ? openNewPostModal
          : () => alert('Select a target group for the post')
      }
      icon={<Add style={{ fontSize: 30 }} />}
    />
  )
}

export default withRefIdUpdate(AddPostBtn)

import React from 'react'
import PropTypes from 'prop-types'
import Post from './Post'
import { isSolid } from '../../../utils/taskHandler'
import Button from '../Buttons/Button'
import FetchPostsBtn from './FetchPostsBtn'
import Keys from '../../../utils/Keys.json'
import actions from '../../../store/actions'
const PostContainer = props => {
  const getOffset = () => {
    const length = props.posts.length
    if (length > 0) {
      return props.posts[length - 1][Keys.CREATED_AT_TS]
    } else {
      return null
    }
  }

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      {isSolid(props.posts)
        ? props.posts.map((post, index) => (
            <Post key={index} index={index} post={post} {...props} />
          ))
        : 'no posts to show here... :"('}

      {isSolid(props.posts) ? (
        <FetchPostsBtn
          {...props}
          posts={props.posts}
          overRidePosts={false}
          offset={getOffset}
        />
      ) : null}
    </div>
  )
}

PostContainer.defaultProps = {
  posts: [],
}

PostContainer.propTypes = {
  posts: PropTypes.array,
}

export default PostContainer

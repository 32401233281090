import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import UserModalForm from '../Form/UserForm'
import { DeleteUserWarning } from '../AlertModal'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import '../styles/modalStyle.css'
import PostForm from '../Form/PostForm'

const PostModal = props => {
  const close = () => {
    props.setShowPostModal(false)
    props.setEditPost(false)
  }

  return (
    <Modal
      backdrop={false}
      isOpen={props.showPostModal}
      size={'lg'}
      toggle={() => {
        close()
      }}
    >
      <ModalHeader
        toggle={() => {
          close()
        }}
      >
        {props.editPost ? 'Post Id: ' : 'New Post'}
      </ModalHeader>
      <ModalBody>
        <div className="userModalBodyWrapper">
          <PostForm selectedPost={props.selectedPost} />
        </div>
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = ({ showPostModal, editPost, selectedPost }) => ({
  showPostModal,
  editPost,
  selectedPost,
})
export default connect(
  mapStateToProps,
  actions
)(PostModal)

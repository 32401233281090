import React from 'react'
import styled from 'styled-components'
import Button from '../Buttons/Button'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import { getRetailPosts } from '../../../services/api'
import isNil from 'lodash/isNil'
import AlertMassages from '../../../utils/AlertMassages'
import Keys from '../../../utils/Keys.json'
import withRefIdUpdate from './withRefIdUpdate'
import { isSolid } from '../../../utils/taskHandler'

const FetchPostsBtn = props => {
  const handlePostsResponse = response => {
    if (response !== undefined && response.status === 200) {
      let fetchedPosts = response.posts
      if (isNil(fetchedPosts)) {
        props.handleAlert({
          text: 'Did not got any posts!',
          type: 'info',
        })
      }
      if (props.overRidePosts) {
        props.setPosts(fetchedPosts)
      } else {
        const posts = props.posts
        props.setPosts([...posts, ...fetchedPosts])
      }
    } else {
      props.handleAlert({
        text: AlertMassages[response.status.toString()],
        type: 'error',
      })
    }
  }

  const _getRetailPosts = () => {
    const { selected_rid, offset, selectedGroup } = props
    const params = {
      rid: selected_rid,
      group: selectedGroup[Keys.OBJECT_ID],
    }
    if (offset !== undefined) {
      params['from_ts'] = offset()
    }

    getRetailPosts(handlePostsResponse, params, props.hideFullScreenLoader)
  }

  const handleFetch = async () => {
    props.showFullScreenLoader()
    await props.updateRefIds()
    _getRetailPosts()
  }

  return (
    <Button
      type="button"
      title={'Add a new post to ' + props.selected_rid}
      color={isSolid(props.selectedGroup) ? 'primary' : 'default'}
      onClick={
        isSolid(props.selectedGroup)
          ? handleFetch
          : () => alert("Select a post's group")
      }
      label={'Fetch Posts'}
    />
  )
}

export default withRefIdUpdate(FetchPostsBtn)
